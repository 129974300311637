import {gql, useMutation, useQuery} from '@apollo/client'
import React, {useState} from 'react'
import arrayMove from 'array-move'
import {DropResult} from 'react-beautiful-dnd'
import {ColorResult, CustomPicker as customPicker, HuePicker, Color, ColorChangeHandler} from 'react-color'
import {ExportedColorProps} from 'react-color/lib/components/common/ColorWrap'
import {Saturation} from 'react-color/lib/components/common'
import {PlusCircleIconSmall, Skeleton} from '../components'
import Page from '../components/page'
import {isValidHex, isAccessible, shadeColor} from '../utils/color'
import {
  Table,
  PageHeading,
  SectionHeading,
  ConfirmDialog,
  Banner,
  Button,
  ModalProps,
  Modal,
  FormInput,
} from '../components/tailwind'
import {TagsGetTagsQuery, TagsGetTagsQuery_tags as Tag} from '../graphql/TagsGetTagsQuery'
import {TagAddTagMutation} from '../graphql/TagAddTagMutation'
import {TagUpdateTagMutation} from '../graphql/TagUpdateTagMutation'
import {TagDeleteTagMutation} from '../graphql/TagDeleteTagMutation'

export const GET_TAGS = gql`
  query TagsGetTagsQuery {
    tags {
      id
      name
      color
      level
    }
  }
`

export const UPDATE_TAG = gql`
  mutation TagUpdateTagMutation($id: ID!, $name: String!, $color: String!, $level: Int!) {
    updateTag(id: $id, name: $name, color: $color, level: $level) {
      id
      name
      color
      level
    }
  }
`

export const ADD_TAG = gql`
  mutation TagAddTagMutation($name: String!, $color: String!) {
    addTag(name: $name, color: $color) {
      id
      name
      color
      level
    }
  }
`

export const DELETE_TAG = gql`
  mutation TagDeleteTagMutation($id: ID!) {
    deleteTag(id: $id)
  }
`

interface TagEditorProps extends ModalProps {
  tag?: Tag
}

const SaturationPicker: React.ComponentClass<ExportedColorProps> = customPicker((props) => (
  <div className="relative h-full">
    <Saturation
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore-start
      radius="4px"
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore-end
      {...props}
    />
  </div>
)) as React.ComponentClass<ExportedColorProps>

interface ColorPickerProps {
  className: string
  color: Color
  onChange: ColorChangeHandler
}

const ColorPicker: React.FC<ColorPickerProps> = ({color, onChange, ...props}) => (
  <div {...props}>
    <div className="h-16">
      <SaturationPicker color={color} onChange={onChange} />
    </div>

    <div className="mt-4">
      <HuePicker
        styles={{
          default: {
            hue: {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore-start
              radius: '4px',
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore-end
            },
            picker: {width: '100%'},
          },
        }}
        color={color}
        onChange={onChange}
      />
    </div>
  </div>
)

const TagEditor: React.FC<TagEditorProps> = ({tag, onDismiss}) => {
  const [name, setName] = useState<string>(tag?.name ?? '')
  const [color, setColor] = useState<string>(tag?.color ?? '#FFFFFF')
  const [nameError, setNameError] = useState<string>()
  const [colorError, setColorError] = useState<string>()
  const [addTag, {loading: adding, error: addError}] = useMutation<TagAddTagMutation>(ADD_TAG)
  const [updateTag, {loading: updating, error: updateError}] = useMutation(UPDATE_TAG)
  const error = addError || updateError
  const busy = adding || updating

  const handleSave = async (e: React.FormEvent | React.MouseEvent) => {
    e.preventDefault()

    setNameError(undefined)
    setColorError(undefined)

    let hasError = false

    if (!name) {
      setNameError('Name is required')
      hasError = hasError || true
    }

    if (!isValidHex(color)) {
      setColorError('Color hex format is required')
      hasError = hasError || true
    }

    if (hasError) {
      return
    }

    if (tag) {
      await updateTag({variables: {id: tag.id, name, color, level: tag.level}})
    } else {
      await addTag({variables: {name, color}})
    }

    onDismiss()
  }

  const handleNameChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value)
  }

  const handleColorChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    let color = e.target.value
    if (isValidHex(color) && !color.startsWith('#')) {
      color = `#${color}`
    }

    setColor(color)
  }

  const handleColorPickerChanged = ({hex: color}: ColorResult) => {
    setColor(color)
  }

  return (
    <Modal onDismiss={onDismiss}>
      <SectionHeading title={`${tag ? 'Edit' : 'Add'} Tag`} />
      <form autoComplete="off" className="mt-3" onSubmit={handleSave}>
        <FormInput required autoFocus label="Tag Name" value={name} error={nameError} onChange={handleNameChanged} />

        <FormInput
          required
          className="mt-4"
          style={{
            backgroundColor: isValidHex(color) ? color : undefined,
            color: isValidHex(color) ? (isAccessible(color) ? 'white' : shadeColor(color, -40)) : undefined,
          }}
          label="Tag Color"
          value={color}
          error={colorError}
          onChange={handleColorChanged}
        />

        <ColorPicker className="mt-4" color={color} onChange={handleColorPickerChanged} />

        <div className="mt-8 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
          <Button fullWidth className="sm:col-start-2" busy={busy} disabled={busy} type="submit" onClick={handleSave}>
            Save
          </Button>
          <Button fullWidth secondary className="mt-3 sm:mt-0 sm:col-start-1" onClick={onDismiss}>
            Cancel
          </Button>
        </div>

        {error && <Banner type="error" content={error.message} className="mt-3" />}
      </form>
    </Modal>
  )
}

const TagsPage: React.FunctionComponent = () => {
  const {data, loading, refetch} = useQuery<TagsGetTagsQuery>(GET_TAGS)
  const [updateTag] = useMutation<TagUpdateTagMutation>(UPDATE_TAG)
  const [deleteTag] = useMutation<TagDeleteTagMutation>(DELETE_TAG)

  const [reorderedTags, setReorderedTags] = useState<Tag[]>()
  const [tagToDelete, setTagToDelete] = useState<Tag>()
  const [tagToEdit, setTagToEdit] = useState<Tag>()
  const [showAddTag, setShowAddTag] = useState<boolean>()

  const tags = reorderedTags || data?.tags?.slice().sort((a, b) => a.level - b.level)

  const handleAddTag = () => {
    setShowAddTag(true)
  }

  const handleAddTagDismissed = () => {
    refetch()
    setShowAddTag(false)
  }

  const handleEditTag = (tag: Tag) => {
    setTagToEdit(tag)
  }

  const handleEditTagDismissed = () => {
    setTagToEdit(undefined)
  }

  const handleDeleteTag = (tag: Tag) => {
    setTagToDelete(tag)
  }

  const handleDeleteConfirmation = async (result: boolean) => {
    setTagToDelete(undefined)

    if (result) {
      await deleteTag({variables: {id: tagToDelete.id}})
      refetch()
    }
  }

  const handleDragEnd = async (result: DropResult) => {
    if (!result.destination) return

    const {index: sourceIndex} = result.source
    const {index: destinationIndex} = result.destination

    const reorderedTags = arrayMove(tags, sourceIndex, destinationIndex)

    setReorderedTags(reorderedTags)

    try {
      await Promise.all(
        reorderedTags
          .map((tag, index) => ({
            tag,
            desiredLevel: index + 1,
          }))
          .filter((x) => x.tag.level !== x.desiredLevel)
          .map((x) => ({
            ...x.tag,
            level: x.desiredLevel,
          }))
          .map((updatedTag) => {
            return updateTag({variables: updatedTag})
          })
      )
    } finally {
      setReorderedTags(undefined)
    }
  }

  if (loading) {
    return (
      <Page title="Tags">
        <Skeleton />
      </Page>
    )
  }

  return (
    <Page title="Tags">
      <PageHeading title="Tags">
        <Button size="small" icon={PlusCircleIconSmall} className="mt-3 md:mt-0" onClick={handleAddTag}>
          Add Tag
        </Button>
      </PageHeading>

      {tags && (
        <Table
          draggable
          items={tags}
          columns={[
            {
              name: 'Name',
              value: (tag) => (
                <div
                  key={tag.name}
                  className="max-w-md px-2 py-1 font-semibold uppercase rounded-sm"
                  style={{
                    backgroundColor: tag.color,
                    fontSize: '11px',
                    color: isAccessible(tag.color) ? 'white' : shadeColor(tag.color, -40),
                  }}
                >
                  {tag.name}
                </div>
              ),
            },
            {
              value: (tag) => (
                <div className="flex justify-end space-x-2 whitespace-nowrap text-right text-sm leading-5">
                  <a
                    className="font-medium text-indigo-600 hover:text-indigo-900 cursor-pointer"
                    onClick={() => handleEditTag(tag)}
                  >
                    Edit
                  </a>
                  <a
                    className="font-medium text-indigo-600 hover:text-indigo-900 cursor-pointer"
                    onClick={() => handleDeleteTag(tag)}
                  >
                    Delete
                  </a>
                </div>
              ),
            },
          ]}
          onDragEnd={handleDragEnd}
        />
      )}

      {tagToDelete && (
        <ConfirmDialog title={`Confirm Delete: ${tagToDelete.name}`} onDismiss={handleDeleteConfirmation}>
          <h1 className="text-gray-700">Are you sure you want to delete this tag?</h1>
          <Banner type="error" content="This action cannot be undone" />
        </ConfirmDialog>
      )}

      {showAddTag && <TagEditor onDismiss={handleAddTagDismissed} />}
      {tagToEdit && <TagEditor tag={tagToEdit} onDismiss={handleEditTagDismissed} />}
    </Page>
  )
}

export default TagsPage
